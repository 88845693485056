require('./bootstrap');

function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("animated");
        }
    }
}
window.addEventListener("scroll", reveal);

function onload() {
    var loads = document.querySelectorAll(".onload");

    for (var i = 0; i < loads.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = loads[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
            loads[i].classList.add("animated");
        }
    }
}
window.addEventListener("pageshow", onload);


$(document).ready(function() {
    $('.nav-dropdown').click(function(event) {
        event.preventDefault();
        var sectionId = $(this).data('target');
        var $targetSection = $('#' + sectionId);

        // Check if the target section is already visible
        if ($targetSection.is(':visible')) {
            $targetSection.slideUp(); // Hide the section
            $('#page-overlay').fadeOut(); // Hide the overlay
            $(this).blur();
        } else {
            // Hide all content sections
            $('.content-section').hide();

            // Fade in the overlay
            $('#page-overlay').fadeIn();

            // Show the clicked section
            $targetSection.slideDown();
        }
    });

    // Hide content, overlay, and mobile nav when clicking outside
    $(document).click(function(event) {
        if (!$(event.target).closest('.main-nav-link, .content-section, .hamburger-button, #mobile-nav').length) {
            $('.content-section').slideUp();
            $('#page-overlay').fadeOut();
            $('#mobile-nav').slideUp(); // Hide mobile nav
        }
    });

    // Mobile navigation toggle
    $('.hamburger-button').click(function(event) {
        event.preventDefault();

        var $mobileNav = $('#mobile-nav');

        if ($mobileNav.is(':visible')) {
            $mobileNav.slideUp();
            $('#page-overlay').fadeOut(); // Hide the overlay when hiding mobile nav
        } else {
            $mobileNav.slideDown();
            $('#page-overlay').fadeIn(); // Show the overlay when showing mobile nav
        }
    });

    // Optional: Hide content and overlay when clicking on the overlay itself
    $('#page-overlay').click(function() {
        $('.content-section').slideUp();
        $(this).fadeOut();
        $('#mobile-nav').slideUp(); // Hide mobile nav
    });

    document.querySelectorAll('.color-radio').forEach(function(radio) {
        radio.addEventListener('change', function() {
            // Remove 'active' class from all labels
            document.querySelectorAll('.color-label').forEach(function(label) {
                label.classList.remove('active');
            });

            // Add 'active' class to the label of the checked radio button
            let label = document.querySelector('label[for="' + this.id + '"]');
            if (label) {
                label.classList.add('active');
            }
        });
    });
});
